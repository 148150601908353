import React, { useContext } from 'react';
// components
import AdultsDropdown from '../components/AdultsDropdown';
import KidsDropdown from '../components/KidsDropdown';
import CheckIn from '../components/CheckIn';
import CheckOut from '../components/CheckOut';
import { RoomContext } from '../context/RoomContext';

const BookForm = () => {
  const {handleClick} = useContext(RoomContext)
  return (
    <form className='h-[300px] w-full lg:h-[70px]'>
      <div className='flex flex-col w-full h-full lg:flex-row gap-y-2'>
        <div className='flex-1 border-r w-[243px] border-2 border-yellow-500'>
          <CheckIn />
        </div>
        <div className='flex-1 border-r w-[243px] border-2 border-yellow-500'>
          <CheckOut />
        </div>
        <div className='flex-1 border-r w-[243px] border-2 border-yellow-500'>
          <AdultsDropdown />
        </div>
        <div className='flex-1 border-r w-[243px] border-2 border-yellow-500'>
          <KidsDropdown />
        </div>
        {/* btn */}
        <button
          onClick={(e) =>handleClick(e)} 
          type='submit' 
          className='btn btn-primary w-[243px]'
        >
          Check now
        </button>
      </div>
    </form>
  );
};

export default BookForm;
